import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Navbar from "./Navbar"
import Footer from "./Footer"
import PageHeader from "../components/elements/PageHeader"

const PageWrapper = ({ children }) => {
  return (
    <>
      <Navbar />
      <Container>
        <PageHeader />
        {children}
      </Container>
      <Footer />
    </>
  )
}

const Container = styled.div`
  margin-top: 75px;
  padding: 50px 5%;
  @media (min-width: 768px) {
    margin-top: 140px;
  }
`

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}
export default PageWrapper
