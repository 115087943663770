import React, { useState, useEffect } from "react"
import { Link as GatsbyLink } from "gatsby"
import { Link as ScrollLink } from "react-scroll"
import makeStyles from "@material-ui/core/styles/makeStyles"

import backgroundImg from "../images/landing/footer-texture.png"
import qPhotoImg from "../images/landing/q-photo-logo.png"

const Footer = () => {
  const [location, setLocation] = useState()
  useEffect(() => {
    const location = window.location.pathname
    setLocation(location)
  }, [])
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.grid}>
        <GatsbyLink to="/" className="logo">
          Build My Book
        </GatsbyLink>
        <div className={classes.linkContainer}>
          <div className="link-group">
            {location == "/" && (
              <ScrollLink
                to="how-it-works"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                How it works
                <div />
              </ScrollLink>
            )}
            <GatsbyLink to="/faq">
              FAQs
              <div />
            </GatsbyLink>
          </div>
          <div className="link-group">
            <GatsbyLink to="/about">
              About
              <div />
            </GatsbyLink>
            <GatsbyLink to="/contact">
              Contact
              <div />
            </GatsbyLink>
            <GatsbyLink to="/products">
              Products
              <div />
            </GatsbyLink>
          </div>
          <div className="link-group">
            <a
              href="https://www.facebook.com/QPhotoFanPage"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
              <div />
            </a>
            <a
              href="https://www.instagram.com/qphotosa/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
              <div />
            </a>
            <a
              href="https://za.pinterest.com/QPhotoSA/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Pintrest
              <div />
            </a>
          </div>
        </div>
      </div>
      <div className={classes.legal}>
        <div>
          <p className="text">&copy; q-photo. all rights reserved.</p>
          <GatsbyLink className="text" to="/terms-and-conditions">
            terms and conditions
          </GatsbyLink>
          <GatsbyLink className="text" to="/privacy-policy">
            privacy policy
          </GatsbyLink>
        </div>
        <a
          className="q-photo"
          href="https://www.qphoto.co.za"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={qPhotoImg} />
        </a>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.section.paddingOne,
    paddingTop: "50px",
    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  grid: {
    textAlign: "center",
    "& .logo": {
      fontFamily: theme.font.saturdayRock,
      fontSize: "1.4rem",
    },
    [theme.breakpoint.tablet]: {
      textAlign: "left",
      display: "flex",
      justifyContent: "space-between",
      "& .logo": {
        fontSize: "2rem",
        paddingTop: "50px",
      },
    },
  },
  linkContainer: {
    display: "grid",
    marginTop: "20px",
    "& .link-group": {
      marginBottom: "20px",
      "& a": {
        display: "inline",
        fontSize: "1rem",
        fontFamily: theme.font.galanoMed,
        marginBottom: "5px",
        cursor: "pointer",
      },
    },
    [theme.breakpoint.tablet]: {
      gridTemplateColumns: "1fr 1fr 1fr",
      marginTop: "50px",
      gridGap: "100px",
      "& a": {
        "& div": {
          width: "0px",
          height: "2px",
          backgroundColor: theme.color.orange,
          transition: "width 0.25s ease",
        },
        "&:hover": {
          "& div": {
            width: "30px",
          },
        },
      },
    },
  },
  legal: {
    justifyContent: "space-between",
    padding: "50px 0 20px 0",
    textAlign: "center",
    "& div": {
      display: "flex",
      flexDirection: "column",
      "& .text": {
        fontSize: "0.8rem",
        marginBottom: "10px",
        fontFamily: theme.font.galanoSemi,
        color: theme.color.grey,
      },
      "& a": {
        transition: "opacity 0.25s ease",
        "&:hover": {
          opacity: "0.5",
        },
      },
    },
    "& .q-photo": {
      "& img": {
        maxWidth: "160px",
      },
    },
    [theme.breakpoint.tablet]: {
      display: "flex",
      "& div": {
        flexDirection: "row",
        "& .text": {
          marginRight: "30px",
        },
      },
    },
  },
}))

export default Footer
