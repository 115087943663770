import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-scroll"
import Button from "@material-ui/core/Button"
import { withStyles } from "@material-ui/core/styles"

const ButtonStyles = withStyles(theme => ({
  root: {
    boxShadow: "none",
    fontSize: "0.8rem",
    padding: "6px 12px",
    lineHeight: 1.5,
    backgroundColor: theme.color.orange,
    border: `2px solid ${theme.color.orange}`,
    fontFamily: theme.font.galanoSemi,
    color: theme.color.white,
    transition: "all 0.25s ease",
    cursor: "pointer",
    borderRadius: "10px",
    "&.small": {
      padding: "10px 15px",
    },
    "&.large": {
      padding: "10px 25px",
    },
    "&:hover": {
      backgroundColor: theme.color.white,
      color: theme.color.black,
    },
    "& .MuiTouchRipple-ripple": {
      color: theme.color.orange,
    },
  },
}))(Button)

const ScrollButton = props => {
  const { id, text, size } = props
  return (
    <Link to={id} spy={true} smooth={true} offset={-100} duration={500}>
      <ButtonStyles variant="contained" className={size}>
        {text}
      </ButtonStyles>
    </Link>
  )
}

ScrollButton.propTypes = {
  id: PropTypes.string,
  size: PropTypes.string,
  text: PropTypes.string,
}
export default ScrollButton
