import React from "react"
import PageWrapper from "../components/PageWrapper"
import styled from "styled-components"

const UserJourneyPage = () => (
  <PageWrapper>
    <Header>
      <h1>User Journey</h1>
    </Header>
    <ContentBlock>
      <p className="padded">
        Choose <span>your photos</span> between 80 to 100 and send them to us
      </p>
      <p className="bold">Upload digital photos</p>
      <p className="padded grey">
        You can use the upload function on the website. Images need to be a
        minimum of 300 dpi. We will contact you if the images are not
        recommended print quality.
      </p>
      <p className="bold">Send us the originals</p>
      <p className="padded grey">
        Send them to us via ARAMAX drop box at selected Pickn’Pays for R99 to
        our physical address and we will digitize them for free. The originals
        will be sent back to you with your photo book.
      </p>

      <p>
        Select your <span>occasion</span>
      </p>
      <p>Wedding</p>
      <p>Baby</p>
      <p>Pet</p>
      <p>Travel</p>
      <p>Special Occasion</p>
      <p>Family</p>
      <p className="padded">Browse our online examples</p>

      <p className="padded">
        Provide a <span>book title</span> and short description{" "}
      </p>
      <p className="padded">
        Select your <span>book type</span> and specify your order
      </p>
      <p className="padded bold">Communications :</p>
      <p>Order confirmation email – what happens next</p>
      <p>Dispatch email & sms</p>
      <p>Survey email</p>
      <p className="padded">Would you like to re-order, it’s gifting time</p>
      <p>Order form for production</p>
    </ContentBlock>
  </PageWrapper>
)

const Header = styled.div`
  margin: 50px 0;
  h1 {
    font-size: 1.5rem;
    font-family: ${props => props.theme.font.galanoBold};
    color: ${props => props.theme.color.grey};
    text-align: center;
  }
  @media (min-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }
  }
`

const ContentBlock = styled.div`
  p {
    font-family: ${props => props.theme.font.galanoMed};
    span {
      font-size: 1.4rem;
      font-family: ${props => props.theme.font.galanoSemi};
    }
  }
  .bold {
    font-family: ${props => props.theme.font.galanoSemi};
  }
  .grey {
    color: ${props => props.theme.color.grey};
  }
  .padded {
    padding-bottom: 20px;
  }
`
export default UserJourneyPage
