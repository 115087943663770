import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"
import { Link as ScrollLink } from "react-scroll"
import ScrollButton from "./elements/ScrollButton"
import Button from "./elements/Button"

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false)
  const [location, setLocation] = useState("/")
  useEffect(() => {
    let location = window.location.pathname
    setLocation(location)
  }, [])
  const toggleIsNavOpen = () => {
    setIsNavOpen(!isNavOpen)
  }
  return (
    <Nav
      isNavOpen={isNavOpen}
      className={location !== "/" && isNavOpen ? "not-home" : null}
    >
      <Logo>
        <GatsbyLink to="/">Build My Book</GatsbyLink>
      </Logo>
      <NavLinks>
        {location == "/" && (
          <ScrollLink
            activeClass="active"
            to="how-it-works"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
            className="basic-link"
          >
            HOW IT WORKS
            <div />
          </ScrollLink>
        )}
        {location != "/" && (
          <GatsbyLink to="/#how-it-works" className="basic-link">
            HOW IT WORKS
            <div className={location == `/` ? "active" : null} />
          </GatsbyLink>
        )}
        <GatsbyLink to="/faq" className="basic-link">
          FAQ
          <div className={location == `/faq` ? "active" : null} />
        </GatsbyLink>
        <GatsbyLink to="/about" className="basic-link">
          ABOUT
          <div className={location == `/about` ? "active" : null} />
        </GatsbyLink>
        {location == "/" && (
          <ScrollButton id="product" size="small" text="GET STARTED" />
        )}
        {location != "/" && (
          <GatsbyLink to="/#product">
            <Button className="navbar">GET STARTED</Button>
          </GatsbyLink>
        )}
      </NavLinks>
      <NavBurger onClick={toggleIsNavOpen} isNavOpen={isNavOpen}>
        <span className="top" />
        <span />
        <span className="bottom" />
      </NavBurger>
    </Nav>
  )
}

// STYLES
const Nav = styled.div`
  background: ${props => props.theme.color.white};
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  padding: ${props => props.theme.section.paddingOne};
  justify-content: space-between;
  overflow: hidden;
  transition: height 0.25s ease;
  height: ${props => (props.isNavOpen ? "300px" : "70px")};
  z-index: 9;
  &.not-home {
    height: ${props => (props.isNavOpen ? "210px" : "70px")};
  }
  @media (min-width: 768px) {
    display: flex;
    height: 140px;
  }
`
const Logo = styled.div`
  height: 75px;
  padding-top: 25px;
  font-size: 1.4rem;
  font-family: ${props => props.theme.font.saturdayRock};
  @media (min-width: 768px) {
    font-size: 2rem;
    padding-top: 20px;
  }
`
const NavLinks = styled.div`
  a {
    text-align: center;
    display: block;
    font-size: 0.8rem;
    text-decoration: none;
    font-family: ${props => props.theme.font.galanoSemi};
    &.basic-link {
      color: ${props => props.theme.color.black};
      padding: 10px 0;
      cursor: pointer;
    }
  }
  @media (min-width: 768px) {
    a {
      display: inline-block;
      margin-left: 30px;
      &.basic-link {
        div {
          background: ${props => props.theme.color.orange};
          height: 2px;
          width: 100%;
          transform: scaleX(0);
          transition: transform 0.25s ease;
          &.active {
            transform: scaleX(1);
          }
        }
        &:hover,
        &.active {
          div {
            transform: scaleX(1);
          }
        }
      }
    }
  }
`

const NavBurger = styled.div`
  position: absolute;
  height: 35px;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  top: 20px;
  right: 30px;
  span {
    height: 3px;
    background: black;
    width: 100%;
    transition: all 0.25s ease;
    &.top {
      transform: ${props => (props.isNavOpen ? "scaleX(0.5)" : "")};
    }
    &.bottom {
      transform: ${props => (props.isNavOpen ? "scaleX(0.5)" : "")};
    }
  }
  @media (min-width: 768px) {
    display: none;
  }
`
export default Navbar
