import React from "react"
import styled from "styled-components"

import landingImg from "../../images/landing/landing-background.jpg"

const PageHeader = () => {
  return <Container />
}
const Container = styled.div`
  height: 40vh;
  background-image: url(${landingImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left 15%;
  @media (min-width: 1024px) {
    height: 50vh;
  }
`
export default PageHeader
